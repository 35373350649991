import { useMemo } from 'react';
import { Dialog, DialogContent, config, useMediaQuery } from '@konta/ui';
import { XCloseLineIcon } from '@konta/icons';
import {
  BlogDialogHeader,
  BlogDialogHeaderContent,
  BlogDialogHeaderClose,
} from './styled';

interface BlogStepData {
  title: string;
  subtitle: string;
  author: string;
  date: string;
  supportText: string;
  avatar: string;
}

interface PostDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  blogContent: BlogStepData;
}
export default function PostDialog({
  isOpen,
  setIsOpen,
  blogContent,
}: PostDialogProps) {
  const isTabletOrMobile = useMediaQuery(config.media['<sm']);

  const dialogContentProps = useMemo(() => {
    if (isTabletOrMobile) {
      return {
        position: 'bottom',
        slideForClose: true,
      } as { position: 'bottom'; slideForClose: true };
    }
    return {
      position: 'center' as const,
    };
  }, [isTabletOrMobile]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent
        size="full"
        css={{ padding: '0px' }}
        {...dialogContentProps}
      >
        {!isTabletOrMobile && (
          <BlogDialogHeader>
            <BlogDialogHeaderContent>
              <BlogDialogHeaderClose>
                <XCloseLineIcon />
              </BlogDialogHeaderClose>
              <span>Soy un titulo de prueba</span>
            </BlogDialogHeaderContent>
          </BlogDialogHeader>
        )}

        <h1>{blogContent.title}</h1>
      </DialogContent>
    </Dialog>
  );
}
