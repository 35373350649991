import {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  useMemo,
} from 'react';
import { Flow, NodeDataType } from 'types/flows';

export interface SlideContent {
  [key: string]: unknown;
  content: ReactNode;
}

interface InsightsDialogContextType {
  isOpen: boolean;
  currentFlowIndex: number;
  currentSlideIndex: number;
  flows: Flow[];
  setFlows: (flows: Flow[]) => void;
  setIsOpen: (isOpen: boolean) => void;
  handleFlowSelect: (flowIndex: number) => void;
  handleFlowChange: (newFlowIndex: number) => void;
  handleSlideChange: (newSlideIndex: number) => void;
  onFlowVisit?: (flow: Flow, flowIndex: number) => void;
  onStepVisit?: (
    step: SlideContent,
    slideIndex: number,
    flow: Flow,
    flowIndex: number,
  ) => void;
  onFlowComplete?: (flow: Flow, flowIndex: number) => void;
  isActiveDialog: boolean;
  setIsActiveDialog: (isActiveDialog: boolean) => void;
}

const InsightsDialogContext = createContext<
  InsightsDialogContextType | undefined
>(undefined);

export function InsightsDialogProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [flows, setFlows] = useState<Flow[]>(() =>
    // TODO: get flows from backend
    Array.from({ length: 20 }, (_, index) => ({
      id: `${index + 1}`,
      type: 'insight',
      title: `Flow ${index + 1}`,
      currentIndexStep: 0,
      img: 'https://i.ibb.co/vxhTb1D/imag12e.png',
      name: `Flow ${index + 1}`,
      created_at: '2024-01-01',
      sent: 100,
      views: 100,
      applied: 0,
      omitted: 0,
      steps: [
        {
          id: 1,
          type: NodeDataType.IMG,
          src: 'https://i.ibb.co/vxhTb1D/image.png',
        },
        {
          id: 2,
          type: NodeDataType.TEXT,
          emoji: '👍',
          title: `Flow ${index + 1}`,
          paragraph: `This is the content for Flow ${index + 1}`,
        },
        {
          id: 3,
          type: NodeDataType.BLOG,
          url: 'https://i.ibb.co/vxhTb1D/image.png',
        },
        {
          id: 4,
          type: NodeDataType.IMG,
          src: 'https://i.ibb.co/vxhTb1D/image.png',
        },
      ],
    })),
  );
  const [isActiveDialog, setIsActiveDialog] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [currentFlowIndex, setCurrentFlowIndex] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const handleFlowSelect = useCallback((flowIndex: number) => {
    setCurrentFlowIndex(flowIndex);
    setCurrentSlideIndex(0);
    setIsOpen(true);
  }, []);

  const handleFlowChange = useCallback((newFlowIndex: number) => {
    setCurrentFlowIndex(newFlowIndex);
    setCurrentSlideIndex(0);
  }, []);

  const handleSlideChange = useCallback((newSlideIndex: number) => {
    setCurrentSlideIndex(newSlideIndex);
  }, []);

  const onFlowVisit = useCallback((flow: Flow, flowIndex: number) => {
    console.log('Flow visited', flow, flowIndex);
  }, []);

  const onStepVisit = useCallback(
    (step: SlideContent, slideIndex: number, flow: Flow, flowIndex: number) => {
      console.log('Step visited', step, flow, flowIndex, slideIndex);
    },
    [],
  );

  const onFlowComplete = useCallback((flow: Flow, flowIndex: number) => {
    console.log('Flow complete', flow, flowIndex);
  }, []);

  const value = useMemo(
    () => ({
      isOpen,
      currentFlowIndex,
      currentSlideIndex,
      flows,
      setFlows,
      setIsOpen,
      handleFlowChange,
      handleSlideChange,
      onFlowVisit,
      onStepVisit,
      onFlowComplete,
      handleFlowSelect,
      isActiveDialog,
      setIsActiveDialog,
    }),
    [
      isOpen,
      currentFlowIndex,
      currentSlideIndex,
      flows,
      setFlows,
      setIsOpen,
      handleFlowChange,
      handleSlideChange,
      onFlowVisit,
      onStepVisit,
      onFlowComplete,
      handleFlowSelect,
      isActiveDialog,
      setIsActiveDialog,
    ],
  );

  return (
    <InsightsDialogContext.Provider value={value}>
      {children}
    </InsightsDialogContext.Provider>
  );
}

export const useInsightsDialog = () => {
  const context = useContext(InsightsDialogContext);
  if (context === undefined) {
    throw new Error(
      'useInsightsDialog must be used within an InsightsDialogProvider',
    );
  }
  return context;
};
