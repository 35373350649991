import { DialogClose, styled } from '@konta/ui';

export const BlogDialogHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '$20 $40',
  borderBottom: '1px solid $gray200',
});

export const BlogDialogHeaderContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  span: {
    fontSize: '$16',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#2B2B34',
  },
});

export const BlogDialogHeaderClose = styled(DialogClose, {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  p: '0',
  color: '$gray500',
  svg: {
    color: '$gray500',
    width: '16px',
    height: '16px',
  },
});
