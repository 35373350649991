interface BlogContent {
  title: string;
  subtitle: string;
  author: string;
  date: string;
  supportText: string;
  avatar: string;
}

// eslint-disable-next-line import/prefer-default-export
export function extractBlogContent(markdown: string): BlogContent {
  const lines = markdown.split('\n');
  let title = '';
  let subtitle = '';
  let author = '';
  let date = '';
  let supportText = '';
  let avatar = '';

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim();
    console.log(line);
    if (line.startsWith('# ') && !title) {
      title = line.substring(2);
    } else if (line.startsWith('## ') && !subtitle) {
      subtitle = line.substring(3);
    } else if (line.startsWith('Author: ')) {
      author = line.substring(8);
    } else if (line.startsWith('Date: ')) {
      date = line.substring(6);
    } else if (line.startsWith('SupporText: ')) {
      supportText = line.substring(11);
    } else if (line.includes('![Avatar]')) {
      avatar = line.substring(line.indexOf('(') + 1, line.indexOf(')'));
    }
  }

  return { title, subtitle, author, date, supportText, avatar };
}
