import React, { useState } from 'react';
import Loader from 'shared/components/Loader';
import { ImgStep } from 'types/flows';
import { Alert, Flex } from '@konta/ui';
import { ImageContainer, FadeInImage } from './styled';

interface ImageStepContentProps {
  step: ImgStep;
}

export default function ImageStepContent({ step }: ImageStepContentProps) {
  const { src } = step;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setError(true);
  };

  return (
    <ImageContainer>
      {isLoading && !error && <Loader />}
      {error && (
        <Flex column>
          <Alert color="error">Error al cargar la imagen</Alert>
        </Flex>
      )}
      <FadeInImage
        alt="Step content"
        hasError={error}
        isLoaded={!isLoading}
        onLoad={handleImageLoad}
        src={src}
        onError={handleImageError}
      />
    </ImageContainer>
  );
}
