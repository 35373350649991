import { InsightsDialog as KontaInsightsDialog } from '@konta/ui';
import { utils } from 'shared/components/XyFlow';
import avatar1 from './img/avatar1.png';
import avatar2 from './img/avatar2.png';
import avatar3 from './img/avatar3.png';
import { useInsightsDialog } from '../InsightsDialogContext';

export default function InsightsDialog() {
  const {
    isOpen,
    setIsOpen,
    flows,
    currentFlowIndex,
    currentSlideIndex,
    handleFlowChange,
    handleSlideChange,
    isActiveDialog,
    setIsActiveDialog,
  } = useInsightsDialog();
  const insightsDialogItems = flows.map((flow) => ({
    ...flow,
    slides: flow.steps.map((step) => ({
      ...step,
      content: utils.getStepContent(step, setIsActiveDialog),
    })),
  }));
  return (
    <KontaInsightsDialog
      isActiveDialog={isActiveDialog}
      open={isOpen}
      onOpenChange={setIsOpen}
      headerProps={{
        avatars: [
          { src: avatar1 as string, id: '1' },
          { src: avatar2 as string, id: '2' },
          { src: avatar3 as string, id: '3' },
        ],
        text: (
          <>
            4.5K de nuestros clientes <b>ahorran hasta un 5%</b> usando esta
            estrategia
          </>
        ),
      }}
      flows={insightsDialogItems}
      currentFlowIndex={currentFlowIndex}
      currentSlideIndex={currentSlideIndex}
      onFlowChange={handleFlowChange}
      onSlideChange={handleSlideChange}
    />
  );
}
