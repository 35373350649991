import { Step, NodeDataType } from 'types/flows';
import TextStepContent from './InsightsContent/TextStepContent';
import ImageStepContent from './InsightsContent/ImageStepContent';
import BlogStepContent from './InsightsContent/BlogStepContent';

// eslint-disable-next-line import/prefer-default-export
export const getStepContent = (
  step: Step,
  setIsActiveDialog?: (isActiveDialog: boolean) => void,
) => {
  switch (step.type) {
    case NodeDataType.TEXT:
      return <TextStepContent step={step} />;
    case NodeDataType.IMG:
      return <ImageStepContent step={step} />;
    case NodeDataType.BLOG:
      return (
        <BlogStepContent step={step} setIsActiveDialog={setIsActiveDialog} />
      );
    default:
      return null;
  }
};
