import {
  CarouselItem,
  useCarouselControl,
  useMediaQuery,
  config,
} from '@konta/ui';
import dayjs from 'dayjs';
import { useInsightsDialog } from './InsightsDialogContext';

export default function useInsights() {
  const { handleFlowSelect, flows } = useInsightsDialog();
  const { carouselRef, buttonState, handleExternalPrev, handleExternalNext } =
    useCarouselControl();

  const carouselItems: CarouselItem[] = flows.map((flow) => ({
    id: flow.id,
    type: 'image',
    content: flow.img,
  }));

  const isMobileOrTablet = useMediaQuery(config.media['<md']);

  const currentMonth = dayjs().format('MMMM');

  return {
    carouselItems,
    carouselRef,
    buttonState,
    handleExternalPrev,
    handleExternalNext,
    isMobileOrTablet,
    currentMonth,
    handleFlowSelect,
  };
}
