import { Section, Carousel } from '@konta/ui';
import Navigation from './Navigation';
import Subtitle from './Subtitle';
import InsightsDialog from './InsightsDialog';
import useInsights from './useInsights';
import { slideStyles } from './styled';

export default function Insights() {
  const {
    carouselItems,
    carouselRef,
    buttonState,
    handleExternalPrev,
    handleExternalNext,
    isMobileOrTablet,
    currentMonth,
    handleFlowSelect,
  } = useInsights();
  return (
    <>
      <InsightsDialog />
      <Section
        title={`Tus insights mensuales: ${currentMonth} 👍🏼`}
        subtitle={<Subtitle isMobileOrTablet={isMobileOrTablet} />}
        subtitleRightElement={
          <Navigation
            handleExternalPrev={handleExternalPrev}
            handleExternalNext={handleExternalNext}
            buttonState={buttonState}
            isVisible={!isMobileOrTablet}
          />
        }
        content={
          <Carousel
            items={carouselItems}
            ref={carouselRef}
            externalControl
            slideStyles={slideStyles}
            onButtonStateChange={buttonState.setButtonState}
            onSlideClick={(item, index) => {
              handleFlowSelect(index);
            }}
          />
        }
      />
    </>
  );
}
