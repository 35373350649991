import { useState, useEffect } from 'react';
import { BlogStep } from 'types/flows';
import { Button, useDialog } from '@konta/ui';
import {
  BlogContainer,
  LoadingSpinner,
  BlogHeader,
  BlogContent,
  BlogFooter,
} from './styled';
import { extractBlogContent } from './utils';
import PostDialog from './PostDialog';

interface BlogStepContentProps {
  step: BlogStep;
  setIsActiveDialog?: (isActiveDialog: boolean) => void;
}

interface BlogStepData {
  title: string;
  subtitle: string;
  author: string;
  date: string;
  supportText: string;
  avatar: string;
}

export default function BlogStepContent({
  step,
  setIsActiveDialog,
}: BlogStepContentProps) {
  const { url } = step;
  const { isOpen, setIsOpen } = useDialog();

  const [blogContent, setBlogContent] = useState<BlogStepData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogContent = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Failed to fetch blog content');
        }
        const markdownContent = await response.text();
        console.log(markdownContent);
        const extractedContent: BlogStepData =
          extractBlogContent(markdownContent);
        setBlogContent(extractedContent);
      } catch (error) {
        console.error('Error fetching blog content:', error);
        setBlogContent(null);
      } finally {
        setLoading(false);
      }
    };

    void fetchBlogContent();
  }, [url]);

  const handleOpenDialog = () => {
    setIsActiveDialog?.(false);
    setIsOpen(true);
  };

  const handleToggleDialog = (newOpen: boolean) => {
    setIsActiveDialog?.(!newOpen);
    setIsOpen(newOpen);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!blogContent) {
    return <p>Failed to load blog content</p>;
  }

  return (
    <BlogContainer>
      <PostDialog
        isOpen={isOpen}
        setIsOpen={handleToggleDialog}
        blogContent={blogContent}
      />
      <BlogHeader>
        <img src={blogContent.avatar} alt="Avatar" />
        <div data-slot="title">
          <span data-slot="author">{blogContent.author}</span>
          <span data-slot="description">{blogContent.supportText}</span>
        </div>
      </BlogHeader>
      <BlogContent>
        <span data-slot="title">{blogContent.title}</span>
        <span data-slot="subtitle">{blogContent.subtitle}</span>
      </BlogContent>
      <BlogFooter>
        <Button color="primary600">Ver más</Button>
        <Button variant="text" color="primary600" onClick={handleOpenDialog}>
          Recordar más adelante
        </Button>
      </BlogFooter>
    </BlogContainer>
  );
}
