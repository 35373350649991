import { NodeProps as XyFlowNodeProps } from '@xyflow/react';

export enum NodeDataType {
  IMG = 'img',
  TEXT = 'text',
  BLOG = 'blog',
}

export interface NodeData extends Record<string, unknown> {
  label: string;
  isFirst?: boolean;
  isLast?: boolean;
  step: Step;
}

export interface NodeProps extends XyFlowNodeProps {
  data: NodeData;
}

export interface CommonStep {
  id: number;
}
export interface TextStep extends CommonStep {
  type: NodeDataType.TEXT;
  emoji: string;
  title: string;
  paragraph: string;
}
export interface ImgStep extends CommonStep {
  type: NodeDataType.IMG;
  src: string;
}
export interface BlogStep extends CommonStep {
  type: NodeDataType.BLOG;
  url: string;
}
export type Step = TextStep | ImgStep | BlogStep;
export interface Flow {
  type: 'insight';
  id: string;
  img: string;
  title: string;
  currentIndexStep: number;
  steps: Step[];
  // TODO: pending to ask to backend
  name: string;
  created_at: string;
  sent: number;
  views: number;
  applied: number;
  omitted: number;
}
